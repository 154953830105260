import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DashboardLayout from './layout/dashboard/Dashboard';
import DashboardAdminLayout from './layout/dashboard/DashboardAdmin';
import { ThemeProvider } from '@emotion/react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { createTheme, Paper } from '@mui/material';
import { grey } from '@mui/material/colors';
import NotifyProvider from './components/Datatable/NotifyProvider';
import FormErrorProvider from './components/Datatable/FormErrorProvider';
import { AuthProvider, RequireAuth } from './components/AuthProvider';
import { AuthProviderAdmin, RequireAuthAdmin } from './components/AuthAdminProvider';
import LoginPage from './pages/client/login/LoginPage';
import LoginAdminPage from './pages/admin/login/LoginPage';
import Recover from './pages/client/recovery';
import RecoverAdmin from './pages/admin/recovery';
import ChangePassword from './pages/client/recovery/changePassword';
import ChangePasswordAdmin from './pages/admin/recovery/changePassword';
import Role from './pages/client/user/role';
import RoleAdmin from './pages/admin/user/role';
import User from './pages/client/user/user';
import UserAdmin from './pages/admin/user/user';
import globalRouter from './components/globalRouter';
import { LoadingProvider } from './components/LoadingProvider';
import DashboardAdmin from './pages/admin/dashboard';
import RoleAccess from './pages/admin/access/role';
import UserAccess from './pages/admin/access/user';
import Empresa from './pages/admin/empresa';
import { SearchProvider } from './components/SearchProvider';
import Location from './pages/admin/product/location';
import ProductType from './pages/admin/product/product_type';
import ServiceType from './pages/admin/product/service_type';
import Product from './pages/admin/product/product';
import Service from './pages/admin/product/service';
import Lead from './pages/client/budget/lead';
import BudgetList from './pages/client/budget';
import Budget from './pages/client/budget/budget2';
import BudgetAdmin from './pages/admin/budget';
import LeadAdmin from './pages/admin/lead';

export default function App() {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;
  return (
    <AuthProviderAdmin>
      <AuthProvider>
        <NotifyProvider>
          <LoadingProvider>
            <FormErrorProvider>
              <SearchProvider>

                <Routes>
                  <Route path="/" element={
                    <RequireAuth>
                      <DashboardLayout />
                    </RequireAuth>
                  }>

                    <Route path="role" element={<Role />} />
                    <Route path="user" element={<User />} />
                    <Route path="lead" element={<Lead />} />
                    <Route path="budget" element={<BudgetList />} />
                    <Route path="/" element={<BudgetList />} />
                    <Route path="budget-create">
                      <Route path=":lead" element={<Budget />} />
                    </Route>
                    <Route path="403" element={<NotAuthorized />} />
                    <Route path="*" element={<NotFound />} />

                  </Route>
                  <Route path="/admin" element={
                    <RequireAuthAdmin>
                      <DashboardAdminLayout />
                    </RequireAuthAdmin>
                  }>
                    <Route path="/admin" element={<Empresa />} />
                    <Route path="/admin/user" element={<UserAdmin />} />
                    <Route path="/admin/user/role" element={<RoleAdmin />} />
                    <Route path="/admin/access/role" element={<RoleAccess />} />
                    <Route path="/admin/access/role" element={<RoleAccess />} />
                    <Route path="/admin/access/user" element={<UserAccess />} />
                    <Route path="/admin/empresa" element={<Empresa />} />
                    <Route path="/admin/product/location" element={<Location />} />
                    <Route path="/admin/product/product_type" element={<ProductType />} />
                    <Route path="/admin/product/service_type" element={<ServiceType />} />
                    <Route path="/admin/product" element={<Product />} />
                    <Route path="/admin/product/service" element={<Service />} />
                    <Route path="/admin/budget" element={<BudgetAdmin />} />
                    <Route path="/admin/lead" element={<LeadAdmin />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="403" element={<NotAuthorized />} />


                  </Route>
                  <Route>
                    <Route path="/admin/recovery" element={<RecoverAdmin />} />
                    <Route path="/admin/change-password" element={<ChangePasswordAdmin />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/admin/login" element={<LoginAdminPage />} />
                    <Route path="/recovery" element={<Recover />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="*" element={<NotFound />} />

                  </Route>
                </Routes>
              </SearchProvider>
            </FormErrorProvider>
          </LoadingProvider>
        </NotifyProvider>
      </AuthProvider>
    </AuthProviderAdmin>
  );
}



function NotFound() {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={2}>

          <Box sx={{ p: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom sx={{ color: grey[900] }}>
              Error 404
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom sx={{ color: grey[900] }}>
              A página que você está procurando não foi encontrada.
            </Typography>
          </Box>
        </Paper>

      </Container>
    </ThemeProvider>
  )
}

function NotAuthorized() {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={2}>

          <Box sx={{ p: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom sx={{ color: grey[900] }}>
              Error 403
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom sx={{ color: grey[900] }}>
              Você não tem permissão para acessar esta página.
            </Typography>
          </Box>
        </Paper>

      </Container>
    </ThemeProvider>
  )
}