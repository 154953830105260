import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Box, Checkbox } from '@mui/material';


export default function RowRadioButtonsGroup({options= [], value, handleChange=()=>{}, multiple=false}) {
  const [selectedValue, setSelectedValue] = React.useState('');
  const style = {
    borderEnterprise: '0px',
    border: '1px solid #e0e0e0',
    pl:1,pr:1,
    m:1,
    minWidth: '200px'
  }
  const styleSelected = {
    borderEnterprise: '0px',
    border: '1px solid #C4161C',
    pl:1,pr:1,
    m:1,
    minWidth: '200px'

  }
    const handleChangeIntern = (event) => {
      setSelectedValue(event.target.value)
      handleChange(event.target.value)
        
    };
    
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={handleChangeIntern}
      >
        {options.map(option => <FormControlLabel  sx={selectedValue == option.value ? styleSelected : style} key={option.value} 
         value={option.value} control={multiple? <Checkbox/> : <Radio/>} label={option.label} />)}
       
      </RadioGroup>
    </FormControl>
  );
}
