import React, { useState } from 'react'
import { FormControl, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../services/api'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'

export default function ProductTypeSelect({handler, value,...props }) {
    const [productTypes, setProductTypes] = useState([])
    const [productType, setProductType] = React.useState(null)
    const error = useFormError()
    React.useLayoutEffect(() => {
        api.get('admin/product/product_type/all/').then(res => {
          setProductTypes(res.data)
        })
      }, [])
  return (
    <FormControl 
    {...props}
    >
      <Autocomplete
        
        id="productTypes-outlined"
        options={productTypes}
        onChange={(event, newValue) => {
          setProductType(newValue)
          handler(newValue?.id)
        }}
       key={(option) => option.id}

        isOptionEqualToValue={(option, value) => value?.id  && option.id == value.id }
        getOptionLabel={(option) => option.description}
        value={value ? productTypes.find(e => e.id == value) : null}
        filterSelectedOptions
        renderInput={(params) => (
          <CustomTextField
          error={error.verify('product_type_id')}
            helperText={error.msgError('product_type_id')}
            {...params}
            label="Tipo de Produto"
          />
        )}
      />
    </FormControl>
  )
}