import React, { useState } from 'react'
import { FormControl, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../services/api'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'

export default function ProductSelect({handler, value,...props }) {
    const [products, setProducts] = useState([])
    const [product, setProduct] = React.useState(null)
    const error = useFormError()
    React.useLayoutEffect(() => {
        api.get('admin/product/all/').then(res => {
          setProducts(res.data)
        })
      }, [])
  return (
    <FormControl 
    {...props}
    >
      <Autocomplete
        multiple
        id="products-outlined"
        options={products}
        onChange={(event, newValue) => {
          setProduct(newValue)
          handler(newValue)
        }}
       key={(option) => option.id}

        isOptionEqualToValue={(option, value) => value?.id  && option.id == value.id }
        getOptionLabel={(option) => option.description}
        value={value}
        filterSelectedOptions
        renderInput={(params) => (
          <CustomTextField
          error={error.verify('products')}
            helperText={error.msgError('products')}
            {...params}
            label="Produtos"
          />
        )}
      />
    </FormControl>
  )
}