import * as React from 'react';
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {ThemeProvider } from '@mui/material/styles';
import {useNavigate } from 'react-router-dom';
import theme from './themeLogin';
import { blue, grey, red } from '@mui/material/colors'
import { CircularProgress, Paper } from '@mui/material';
import { useAuth } from '../../../components/AuthProvider';
import CustomTextField from '../../../components/CustomTextField';
import backgroundImg from '../../../components/imgs/background.jpg'

export default function SignIn() {
 
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const auth = useAuth()
  React.useEffect(() => {
    document.title = "IPV7 Enterprise - Login"
    }, [])
  const location = useLocation()


  const [errorInput, setErrorInput] = React.useState({
    username: false,
    password: false,
  })
  const [errorMsg, setErrormsg] = React.useState({
    severity: "error",
    msg: "",
    show: false
  })
  const loginApi = (cred) => {
    setLoading(true)
    let from = location.state?.from?.pathname || "/";
    const redirectTo = () => {

      navigate(from, { replace: true })
    }
    const cbErr = () => {
      setErrormsg({
      msg: "Credenciais inválidas.", show: true, severity: 'error'
    })
      setLoading(false)
  }
    auth.signin(cred, redirectTo, cbErr)

  }

  const handleSubmit = (event) => {
    setErrorInput(obj => ({ ...obj, username: false, password: false }))
    setErrormsg(errorMsg => ({ ...errorMsg, show: false }))
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const cred = ({
      username: data.get('username'),
      password: data.get('password'),
    });
    if (cred.password.length < 8) {
      setErrorInput(obj => ({ ...obj, password: true }))
      return;
    }
    if (cred.username.length < 1) {
      setErrorInput(obj => ({ ...obj, username: true }))
      return;
    }
    if (!cred.username || !cred.password || cred.password.length < 5 || cred.username.length < 1) {
      setErrormsg(m => ({ show: true, msg: "Por favor preencha o formulário corretamente.", severity: 'warning' }))
      return;
    }
    loginApi(cred)
  };

  return (
   <ThemeProvider theme={theme}>

      <div component="main" 
        style={{
          p:0,
      
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          justifyContent: 'center',
          height: '100vh',
          backgroundImage: `url(${backgroundImg})`,
          backgroundPosition: 'center',
          backgroundSize: 'contain'
        }}
      >
        <CssBaseline />
        <Box minWidth={300} sx={{
          display: 'flex',
         }}
        
        >

          <Box component={"div"}
            sx={{
              height: '100vh',
              m:0,
              backgroundColor: "#C4161C",
              opacity: 0.99,
              
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >

            <Paper elevation={2} component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1,
                maxHeight: 400,
                width: 500,
                p:5,
                m:5,
                backgroundColor: 'white',
                
             }}>
              <Typography component="h1" variant="h5" align="center">
                Area do Cliente</Typography>
              <CustomTextField
                error={errorInput.username}
                margin="normal"
                required
                fullWidth
                id="username"
                label="Usuário"
                disabled={loading}
                name="username"
                autoComplete="username"
                autoFocus
                helperText={errorInput.username ? "Verifique este campo" : ''}
                />
              <CustomTextField
                error={errorInput.password}
                disabled={loading}
                margin="normal"
                required
                fullWidth
                name="password"
                minLength="8"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                helperText={errorInput.password ? "Verifique este campo. A senha deve ter no mínino 8 caracteres" : ''}
              />
              <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2,
                  backgroundColor: grey[900],
                  
                 }}
                 >
                {loading ? <CircularProgress  size="2rem" /> : "Entrar"}
              </Button>
              <Link to={"/recovery"} style={{ textDecoration: 'none', color: red[900] }}>
                <Typography variant="body2" color="text.secondary" align="center">
                  Esqueci minha senha
                </Typography>
              </Link>
              <Alert severity={errorMsg.severity}
                sx={{ display: errorMsg.show ? '' : 'none' }}
                >{errorMsg.msg}</Alert>
            </Paper>
          </Box>
        </Box>
      </div>
    </ThemeProvider>

  );
}
