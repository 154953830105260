import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef} from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import {RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import CustomTextField from '../../../components/CustomTextField';
import { useAuth } from '../../../components/AuthAdminProvider';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import FormDialog from '../../../components/Datatable/FormDialog';
import Datatable from '../../../components/Datatable';
import ProductSelect from '../../../components/ProductSelect';
import ServiceTypeSelect from '../../../components/ServiceTypeSelect';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { green, red } from '@mui/material/colors';

export default function Service() {
    const notify = useNotify
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [ refresh, setRefresh ] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Mercurio - Recurso"
      }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
               handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={()=>setRefresh(!refresh)}
                title='Recurso'
                endpoint='/admin/product/service/'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('datatable./admin/product/service/'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('product_service.create')}
                                    onClick={() => setFormState({ open: true, id: null })} 
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: "description",
                        label: "Nome",
                    },
                    {
                        name: "active",
                        label: "Ativo",
                        options: {
                            filter: false,
                            customBodyRender: value => {
                                return value ? <DoneIcon sx={{ color: green[400]}}/> : <ClearIcon sx={{color: red[400]}} />
                            }
                        }
                    },
                    {
                        name: "value",
                        label: "Preço",
                        options: {
                            customBodyRender: value => {
                              const floatValue = parseFloat(value);
                              const formattedValue = floatValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                              return <span>{formattedValue}</span>;
                            },
                        }
                    },
                    {
                        name: "max_quantity",
                        label: "Quantidade Máxima",
                    },
                    {
                        name: "min_quantity",
                        label: "Quantidade Mínima",
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'updated_at',
                        label: 'Atualizado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('product_service.update')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar'}) {
    const notify = useNotify()
    const error = useFormError()
    const [permissions, setPermissions] = React.useState([])
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {
        description: '',
        value: '',
        max_quantity: '',
        min_quantity: '',
        limit: '',
        products: [],
        active: true,
        options: "",
        type_number: 0,
        unit: "",
        type_service: ""
    }
    const handleChangeCheck = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked
        });
    };
    const [state, setState] = useState(modelState)
    
    useEffect(() => {
        setState(modelState)
        if (!open) return
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/admin/product/service', id).then(res => {
                setState(res.data.data)
                setIsLoaded(true)

            }).catch(e => {console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id,open])

 

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {
        const payload = {...state, value:  state.value ? state.value.replace(',', '.') : null}
        if (id) {
            update('/admin/product/service', id, payload).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {                   
                    if (res.data.error){
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerClose()
                    notify.setNotify({ open: true, message: 'Recurso atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/admin/product/service/create', payload)
            .then(res => {               
                if (res.data.error){
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return
                }
                if (res.data.errors) {
                    error.setErrors(res.data.errors)
                    return
                }
                handlerSubmit()
                notify.setNotify({ open: true, message: 'Recurso criado com sucesso.', severity: 'success' })
                error.setErrors([])
            })
            .catch(e => 
                notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

        <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Recurso</Typography>
                        <IconButton disabled={!auth.hasPerm("product_service.delete")} 
                        sx={{display:id? 'block':'none'}}
                        onClick={()=>{
                             if (confirm('Deseja excluir o serviço?')){
                                    delete_one('/admin/product/service', id).then(res => {
                                        notify.setNotify({ open: true, message: 'Recurso deletado com sucesso.', severity: 'success' })
                                        handlerClose()
                                        handlerSubmit()
                                    })
                             }
                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CustomTextField
                        sx={{ mb: 2, mt:1}}
                        error={error.verify('description')}
                        helperText={error.msgError('description')}
                        label='Nome'
                        value={state.description || ''}
                        name={'description'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2}}
                        error={error.verify('value')}
                        helperText={error.msgError('value')}
                        label='Valor'
                        value={state.value || ''}
                        name={'value'}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                          }}
                       
                        onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9,]/g, '').replace(/(,.*),/g, '$1')
                            setState({ ...state, value })
                        }}
                    />
                    <CustomTextField
                        sx={{ mb: 2}}
                        error={error.verify('min_quantity')}
                        helperText={error.msgError('min_quantity')}
                        label='Quantidade Mínima'
                        value={state.min_quantity || ''}
                        name={'min_quantity'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2}}
                        error={error.verify('max_quantity')}
                        helperText={error.msgError('max_quantity')}
                        label='Quantidade Máxima'
                        value={state.max_quantity || ''}
                        name={'max_quantity'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2}}
                        disabled={state.type_number != 0}
                        error={error.verify('options')}
                        helperText={error.msgError('options')}
                        label='Lista de Opções(Ex: 1,2,3)'
                        value={state.options || ''}
                        name={'options'}
                        onChange={handleChange}
                    />
                    <FormControl fullWidth>
                    <InputLabel id="type-number-select-label">Tipo de Número</InputLabel>
                    <Select
                        labelId='type-number-select-label'
                        sx={{ mb: 2}}
                        label="Tipo de Número"
                        value={state.type_number }
                        name={"type_number"}
                        size='small'
                        onChange={handleChange}>
                        <MenuItem value={0}>Qualquer número</MenuItem>
                        <MenuItem value={1}>Apenas ímpares</MenuItem>
                        <MenuItem value={2}>Apenas pares</MenuItem>
                        </Select>
                    </FormControl>
                    <CustomTextField
                        sx={{ mb: 2}}
                        error={error.verify('unit')}
                        helperText={error.msgError('unit')}
                        label='Unidade'
                        value={state.unit || ''}
                        name={'unit'}
                        onChange={handleChange}
                    />
                    <ServiceTypeSelect handler={(value) => setState({ ...state, type_service: value })}
                        sx={{ mb: 2 }}
                        value={state.type_service} />
                    <ProductSelect handler={(value) => setState({ ...state, products: value })}
                      sx={{ mb: 2 }}
                      value={state.products}
                    />
                    <FormControlLabel sx={{ ml: 1, mt: 1 }}
                        control={
                            <Checkbox checked={state.active || false} onChange={handleChangeCheck} name="active"
                            />
                        }
                        label="Ativo"
                    />
                </Box>
            </FormDialog>
        </Box>
    )
}
