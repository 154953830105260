import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import theme from '../theme';
import { grey } from '@mui/material/colors';
import { Collapse, List, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useAuth } from '../components/AuthProvider';
import { useAuth as useAuthAdmin } from './AuthAdminProvider';
import { ThemeProvider, useTheme } from '@emotion/react';
import { min } from 'moment';

const iconStyle = {
    color: grey[100],

}

export default function ItemMenu({name, icon, path, perm="", children, admin=false}) {
    const auth = admin ? useAuthAdmin() : useAuth()
    
    const has_perm = typeof perm  == "string" ? auth.hasPerm(perm.toString()) : true
    return (
        <Link to={path} style={{ textDecoration: 'none', color: grey[100],
        display: has_perm ? "" : "none"
        }} >
            <ItemMenuBase
            icon={icon}
            name={name}
            perm={perm}
            />
        </Link>
    )
}

export function ItemMenuBase(props) {
    const auth = useAuth()
    
    return (
        <>
        <ListItemButton onClick={props.onClick} {...props}>
        <ListItemIcon sx={{minWidth:36}}>
            {React.cloneElement(props.icon, {style: iconStyle})}     
        </ListItemIcon>
        <ListItemText primary={props.name} />
        {props.inside}
        </ListItemButton>
        {props.children}
        </>
       
    )

}


export function ListItemMenu({name, icon, children, perm=""}) {
    const theme = useTheme({
        typography:{
            fontFamily:[
              'sans-serif',
              'Roboto',
              'Montserrat',
              'Coolvetica',
            ],
          },
    })
    
    const [open, setOpen] = React.useState(localStorage.getItem("menuitem."+name) ? JSON.parse(localStorage.getItem("menuitem."+name)).rowsPerPage : true)
    const auth = useAuth()
    const has_perm = perm ? auth.hasPerm(perm) : true
    return (
        <>
        <ThemeProvider theme={theme}>
        <ItemMenuBase

        name={
            <Typography variant="button" sx={{color: grey[100], 
                fontFamily:"Coolvetica,",
                letterSpacing:"0.1rem",
                fontSize: "0.8rem",
            
            
            }}
                >{name}</Typography>}
        icon={icon}
        inside={open ? <ExpandLess style={iconStyle}/> : <ExpandMore style={iconStyle}/>}
        onClick={() => {
            setOpen(!open)
            localStorage.setItem("menuitem."+name, JSON.stringify({rowsPerPage: !open}))
           
        }}
        sx={{
            backgroundColor: "#26282e",
            color: grey[100],
            boxShadow: 1,
            display: has_perm ? "" : "none"
        
    }}
        
        >

            
        <Collapse in={open} timeout={'auto'} unmountOnExit>
            <List component="div" disablePadding >
                {children}
            </List>
        </Collapse>
        </ItemMenuBase>
        </ThemeProvider>
        </>
    )
}
