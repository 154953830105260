import React, { useEffect, useState } from 'react';
import Datatable from '../../../components/Datatable';
import FormDialog, { Text } from '../../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, IconButton, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../../components/AuthAdminProvider';
import EmpresaSelect from '../../../components/EmpresaSelect';
import CustomTextField from '../../../components/CustomTextField';

export default function Role() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [ refresh, setRefresh ] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Enterprise - Cargos Acesso"
      }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
                handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={()=>setRefresh(!refresh)}
                title='Cargos'
                endpoint='/admin/access/role/'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('datatable./admin/access/role'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('role_client.create')}
                                    onClick={() => setFormState({ open: true, id: null })} 
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: "empresa",
                        label: "Empresa",
                    },
                    {
                        name: 'description',
                        label: 'Descrição',
                    },
                    
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('role_client.update')}
                                                onClick={() => {setFormState({ open: true, id: tableMeta.rowData[0] })
                                            console.log(tableMeta.rowData)
                                            }}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar'}) {
    const notify = useNotify()
    const error = useFormError()
    const [permissions, setPermissions] = React.useState([])
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {
        description: '',
        permissions: [],
        empresa: null   
    }
    const [state, setState] = useState(modelState)
    const  getPermissions = ()=>{
        api.get('/admin/access/role/permission/').then(res=>{
            setPermissions(res.data)
            
        }).catch(err=>
            setPermissions([])
            )
       
        .catch(err=>console.log(err))
      }
    useEffect(() => {
        setState(modelState)
        if (!open) return
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/admin/access/role', id).then(res => {
                setState(res.data)
                setIsLoaded(true)

            }).catch(e => {console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id,open])

    useEffect(() => {
        if (open) {
            getPermissions()
        }

    }, [open])

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {
        const payload = {...state, permissions: state.permissions.map(p=>p.id)}
        if (id) {
            update('/admin/access/role', id, payload).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error){
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Cargo atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/admin/access/role/create', payload)
            .then(res => {
                if (res.data.error){
                    notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                    return
                }
                if (res.data.errors) {
                    error.setErrors(res.data.errors)
                    return
                }
               
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Cargo criado com sucesso.', severity: 'success' })
                    error.setErrors([])
               
            })
            .catch(e => 
                notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Cargo</Typography>
                        <IconButton disabled={!auth.hasPerm("role_client.delete")} 
                        sx={{display:id? 'block':'none'}}
                        onClick={()=>{
                             if (confirm('Deseja excluir o cargo?')){
                                    delete_one('/admin/access/role', id).then(res => {
                                        notify.setNotify({ open: true, message: 'Cargo deletado com sucesso.', severity: 'success' })
                                        handlerSubmit()
                                    })
                             }
                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <EmpresaSelect handler={(value) => setState({ ...state, empresa: value })}
                      value={state.empresa} 
                    />
                    <CustomTextField
                        sx={{ mb: 2, mt: 2 }}
                        error={error.verify('description')}
                        helperText={error.msgError('description')}
                        label='Descrição'
                        value={state.description || ''}
                        name={'description'}
                        onChange={handleChange}
                    />
                    <Autocomplete
                    multiple
                    
                    id="tags-outlined"
                    options={permissions}
                    onChange={(event, newValue) => {
                        setState({...state, permissions: newValue})
                    }}
                    
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.description}
                    value={state.permissions || []}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <CustomTextField
                        {...params}
                        label="Permissões"
          />
        )}
      /> 
                    
                   
                </Box>

            </FormDialog>
        </Box>
    )
}


