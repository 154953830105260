import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../../../components/AuthProvider';
import theme from '../../../theme';
import logo from '../login/logo_black.png'
import { redirect, useNavigate, Navigate } from 'react-router-dom';
import { Paper } from '@mui/material';
import api from '../../../services/api';
import CustomTextField from '../../../components/CustomTextField';

export default function SignIn() {
  React.useEffect(() => {

    if (auth.user != null) {
      navigate('/')
    }
  })
  const navigate = useNavigate()
  const auth = useAuth()
  React.useEffect(() => {
    document.title = "Hermes - Alterar senha"
  }, [])


  const [errorInput, setErrorInput] = React.useState({
    username: false,
    password: false,
  })
  const [errorMsg, setErrormsg] = React.useState({
    severity: "error",
    msg: "",
    show: false
  })
  const loginApi = (cred) => {
    let from = location.state?.from?.pathname || "/";
    const redirectTo = () => {

      navigate(from, { replace: true })
    }
    const cbErr = () => setErrormsg({
      msg: "Credenciais inválidas.", show: true, severity: 'error'
    })
    auth.signin(cred, redirectTo, cbErr)

  }

  const handleSubmit = (event) => {
    setErrorInput(obj => ({ ...obj, username: false, password: false }))
    setErrormsg(errorMsg => ({ ...errorMsg, show: false }))
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const cred = ({
      email: data.get('email'),

    });


    if (cred.email.length < 5) {
      setErrorInput(obj => ({ ...obj, email: true }))
    }
    if (!cred.email) {
      setErrormsg(m => ({ show: true, msg: "Por favor preencha o formulário corretamente.", severity: 'warning' }))
      return;
    }
    api.post("recovery/", cred).then(res => {

      setErrormsg(m => ({ show: true, msg: "Um email foi enviado para você com as instruções para a recuperação de senha.", severity: 'success' }))
      setTimeout(() => {
        navigate('/login')
      }, 3000)
    }).catch(err => {
      setErrormsg(m => ({ show: true, msg: "Um email foi enviado para você com as instruções para a recuperação de senha.", severity: 'success' }))
      setTimeout(() => {
        navigate('/login')
      }, 3000)
    })
  };

  return (

    <Container component="main" maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: "#2B2E35",
        }}
      >
      <CssBaseline />
      <Container maxWidth="xs" sx={{ mb: 4 }}>

      <Paper elevation={2}
            sx={{
              p: 5,
              backgroundColor: "#EDF1FC",
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >

          <img  style= {{ width: '100%'}} src={logo}></img>


          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

            <CustomTextField
              error={errorInput.email}
              margin="normal"
              required
              fullWidth
              name="email"
              minLength="8"
              label="Insira seu email"
              type="email"
              id="email"
              autoComplete="current-email"
              helperText={errorInput.email ? "Verifique este campo." : ''}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Recuperar senha
            </Button>
            <Alert severity={errorMsg.severity}
              sx={{ display: errorMsg.show ? '' : 'none' }}
            >{errorMsg.msg}</Alert>
          </Box>
        </Paper>
      </Container>
    </Container>

  );
}
