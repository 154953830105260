import React, { useEffect, useState } from 'react';
import Datatable from '../../components/Datatable';
import FormDialog from '../../components/Datatable/FormDialog';
import { Autocomplete, Box, Button, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography, setRef } from '@mui/material';
import api, { create, get_one, update, delete_one } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import { useNotify } from '../../components/Datatable/NotifyProvider';
import { useFormError } from '../../components/Datatable/FormErrorProvider';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../../components/AuthAdminProvider';
import EmpresaSelect from '../../components/EmpresaSelect';
import moment from 'moment';
import CustomTextField from '../../components/CustomTextField';
import ProductSelect from '../../components/ProductSelect';

export default function Role() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Enterprise - Empresa"
    }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
                handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Empresas'
                endpoint='/admin/empresa'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem('datatable./admin/empresa'); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Adicionar">
                                <IconButton disabled={!auth.hasPerm('empresa.create')}
                                    onClick={() => setFormState({ open: true, id: null })}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: false
                        }
                    },
                    {
                        name: "name",
                        label: "Nome",
                    },
                    {
                        name: 'fantasy_name',
                        label: 'Nome Fantasia',
                    },
                    {
                        name: 'cnpj',
                        label: 'cnpj',
                    },
                    {
                        name: 'status_description',
                        label: 'Status',

                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'updated_at',
                        label: 'Atualizado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Editar">
                                            <IconButton disabled={!auth.hasPerm('empresa.update')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <EditIcon />

                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const [permissions, setPermissions] = React.useState([])
    const auth = useAuth()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const modelState = {
        name: '',
        fantasy_name: '',
        cnpj: '',
        obs: '',
        products: [],
        status: 1,
        discount: 0
    }
    const [state, setState] = useState(modelState)

    useEffect(() => {
        setState(modelState)
        if (!open) return
        error.setErrors([])
        if (id) {
            setIsLoaded(false)
            get_one('/admin/empresa', id).then(res => {
                setState(res.data.data)
                console.log(res.data.data)
                setIsLoaded(true)

            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id, open])



    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {
        if (id) {
            update('/admin/empresa', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error) {
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Empresa atualizada com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/admin/empresa/create', state)
                .then(res => {
                    if (res.data.error) {
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerSubmit()
                    notify.setNotify({ open: true, message: 'Empresa criada com sucesso.', severity: 'success' })
                    error.setErrors([])
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }

    }
    return (
        <Box component={"form"}
            onSubmit={wrapperSubmit}
            sx={{ m: 2 }}
            display={'flex'}
            flexWrap={'wrap'}
        >

            <FormDialog
                isLoaded={isLoaded}
                title={
                    <Box display={"flex"} justifyContent={'space-between'}>
                        <Typography variant="h6" component="div">{id ? 'Editar' : 'Adicionar'} Empresa</Typography>
                        <IconButton disabled={!auth.hasPerm("empresa.delete")}
                            sx={{ display: id ? 'block' : 'none' }}
                            onClick={() => {
                                if (confirm('Deseja excluir a empresa?')) {
                                    delete_one('/admin/empresa', id).then(res => {
                                        notify.setNotify({ open: true, message: 'Empresa deletada com sucesso.', severity: 'success' })
                                        handlerClose()
                                        handlerSubmit()
                                    })
                                }
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={btnSubmit}
                btnClose={btnClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CustomTextField
                        sx={{ mb: 2, mt: 1 }}
                        error={error.verify('name')}
                        helperText={error.msgError('name')}
                        label='Descrição'
                        value={state.name || ''}
                        name={'name'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('fantasy_name')}
                        helperText={error.msgError('fantasy_name')}
                        label='Nome Fantasia'
                        value={state.fantasy_name || ''}
                        name={'fantasy_name'}
                        onChange={handleChange}
                    />
                    <CustomTextField
                        sx={{ mb: 2 }}
                        error={error.verify('cnpj')}
                        helperText={error.msgError('cnpj')}
                        label='CNPJ'
                        value={state.cnpj || ''}
                        name={'cnpj'}
                        onChange={handleChange}
                    />
                    <ProductSelect handler={(value) => setState({ ...state, products: value })}
                        value={state.products}
                        sx={{ mb: 2 }}
                    />
                    <Box display={"flex"}>
                    <SelectStatus
                        value={state?.status || ""}
                        onChange={(e) => setState({ ...state, status: e.target.value })} />

                        <CustomTextField
                            sx={{ mb: 2, ml:2 }}
                            error={error.verify('discount')}
                            helperText={error.msgError('discount')}
                            label='Desconto'
                            value={state.discount || ''}
                            name={'discount'}
                            onChange={handleChange}
                            InputProps={{
                                type: 'number',
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,

                            }}
                        />
                    </Box>
                    <CustomTextField
                        sx={{ mb: 2, mt: 1 }}
                        multiline={true}
                        minRows={3}
                        error={error.verify('obs')}
                        helperText={error.msgError('obs')}
                        label='Observação'
                        value={state.obs || ''}
                        name={'obs'}
                        onChange={handleChange}
                    />
                </Box>

            </FormDialog>
        </Box>
    )
}


const SelectStatus = ({ value, onChange }) => {
    const [status, setStatus] = useState([])

    useEffect(() => {
        api.get('/admin/empresa/status/').then(res => {
            setStatus(res.data)
        })
    }, [])

    return (
        <FormControl fullWidth sx={{ mb: 1 }} size='small'>
            <InputLabel id="label-empresa-status">Status</InputLabel>
            <Select
                labelId="label-empresa-status"
                id="label-status"
                value={value}
                label="Status"
                onChange={onChange}
            >

                {status?.map(item => <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>)}
            </Select>
        </FormControl>
    )
}