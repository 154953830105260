import React, { useState } from 'react'
import { FormControl, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import api from '../services/api'
import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useFormError } from './Datatable/FormErrorProvider'
import CustomTextField from './CustomTextField'

export default function ServiceTypeSelect({handler, value,...props }) {
    const [types, setTypes] = useState([])
    const [service, setType] = React.useState(null)
    const error = useFormError()
    React.useLayoutEffect(() => {
        api.get('admin/product/service-type/all/').then(res => {
          setTypes(res.data)
        })
      }, [])
  return (
    <FormControl 
    {...props}
    >
      <Autocomplete

        id="types-outlined"
        options={types}
        onChange={(event, newValue) => {
          setType(newValue)
          handler(newValue?.id)
        }}
       key={(option) => option.id}

        isOptionEqualToValue={(option, value) => value?.id  && option.id == value.id }
        getOptionLabel={(option) => option.description}
        value={value ? types.find(t => t.id == value) : null}
        filterSelectedOptions
        renderInput={(params) => (
          <CustomTextField
          error={error.verify('type_service')}
            helperText={error.msgError('type_service')}
            {...params}
            label="Tipo de Recurso"
          />
        )}
      />
    </FormControl>
  )
}